<template>
  <div class="main m-l">
    <div class="h1">{{$t('My homepage ad')}}</div>
    <div class="form-wrap">
      <div class="group">
        <div class="group-item">
          <input type="text" autocomplete="off" :placeholder="$t('Title')" v-model.trim="title"/>
        </div>
        <!--<div class="group-item">
          <textarea rows="8" maxlength="500" placeholder="简介" v-model.trim="introduction"></textarea>
        </div>-->
      </div>
      <div class="form-item">
<!--        <div id="editor"></div>-->
        <!-- <froala :config="froalaConfig" v-model="introduction"/> -->
        <editorElem v-model="introduction"/>
      </div>
      <el-upload class="upload-list"
                 accept="image/*"
                 action="/v2/admin/common/file"
                 list-type="picture-card"
                 :show-file-list="false"
                 :on-success="handleCoverUrlSuccess">
        <img class="img" :src="photoUrl" v-if="photoUrl"/>
        <div class="item btn-upload" slot="default" v-else>
          <img src="../../../assets/images/icon/plus.png"/>
          <span>{{$t('UploadPhotos')}}</span>
        </div>
      </el-upload>
      <div class="actions">
        <button class="button button-primary" type="button" @click="onSubmit">{{$t('Save')}}</button>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import VueFroala from 'vue-froala-wysiwyg';
  import editor from '../../../mixins/editor';
  import {saveNews, getNewsDetail} from '../../../api/admin-business';
  import editorElem from '../../../components/EditorBar';
  Vue.use(VueFroala);
  export default {
    name: 'AdminInformationEdit',
    data() {
      return {
        language: '',
        newsId: '',
        title: '',
        introduction: '',
        photoUrl: '',
        editor: ''
      };
    },
    components: {editorElem},
    mixins: [editor],
    created() {
      this.language = this.$route.query.language || this.$i18n.locale;
      this.newsId = this.$route.query.id || '';
      this.getNewsDetail();
    },
    methods: {
      // 获取详情
      getNewsDetail() {
        if (this.newsId) {
          getNewsDetail(this.newsId, this.language).then(res => {
            Object.assign(this.$data, res.value || {});
          });
        }
      },
      // 上传封面
      handleCoverUrlSuccess(res, file) {
        const {code, value} = res;
        if (code === 200) {
          this.photoUrl = value.url;
        }
      },
      // 存储
      onSubmit() {
        const data = {
          newsId: this.newsId,
          languageType: this.language,
          title: this.title,
          introduction: this.introduction,
          photoUrl: this.photoUrl
        };

        if (!data.title) {
          this.$message.warning(this.$t('PleaseEnterATitle'));
          return false;
        }
        if (!data.introduction) {
          this.$message.warning(this.$t('PleaseEnterTheContent'));
          return false;
        }
        if (!data.photoUrl) {
          this.$message.warning('請上傳圖片');
          return false;
        }

        const loading = this.$loading({text: 'Loading'});
        saveNews(data).then(res => {
          loading.close();
          this.$message.success(this.$t('SaveSuccessfully'));
          this.$router.go(-1);
        }).catch(() => {
          loading.close();
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .main{
    .h1{font-size:24px;}
  }
  .form-wrap{
    margin-top:32px;
    .form-item{margin-top:16px;}
    .ipt{
      width:100%;height:60px;padding:0 20px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    .group{
      padding:0 20px;background-color:#f4f4f4;
      input{width:100%;height:62px;border:none;background-color:transparent;}
      textarea{width:100%;padding:20px 0;border:none;background-color:transparent;}
    }
    .group-item{
      border-bottom:1px dashed #e0e0e0;
      &:last-child{border-bottom:none;}
    }
    .actions{margin-top:40px;text-align:center;}
  }
  .upload-list{
    margin-top:16px;
    /deep/ .el-upload--picture-card,
    /deep/ .el-upload-list--picture-card .el-upload-list__item{width:140px;height:140px;line-height:1;border:none;background-color:#f4f4f4;border-radius:0;}
    .item{width:100%;height:100%;}
    .btn-upload{
      overflow:hidden;color:#999;font-size:16px;
      img{display:block;margin:40px auto 8px;}
    }
  }
</style>
